import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import Tabs from "./Tabs";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import "swiper/css/pagination";
// import { Document, Page } from 'react-pdf';
import "../CSS/Data.css";
import prodList from "../Images/Cama Axia - Brochure.jpeg"
// import mela from "../images/product/melamine.jpg";
// import methal from "../images/product/methal.jpg";
// import Citric from "../images/product/citric-acid.jpg";
import Zoom from "react-reveal/Zoom";
import Footer from "../Component/Footer";
// import { useLocation } from "react-router-dom";
import Dete from "../Component/Dete";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { useLayoutEffect } from "react";
import { Navigation } from "swiper";
import "swiper/css/pagination";
import "swiper/css";
import Bounce from 'react-reveal/Bounce'
import Fade from 'react-reveal/Fade';


SwiperCore.use([Autoplay, Pagination]);

const ProductData = () => {
  const { urlType } = useParams();

  useEffect(() => {
    if (urlType == "search") {
      window.scrollTo(0, 570)
    }
  });
  const swiperRefLocal = useRef();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);
  // const newLinkStyles = ({ isActive }) => {
  //   return {
  //     fontweight: isActive ? "red" : "white",
  //   };
  // };
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState(Dete);
  const [tabIndex, setTabIndex] = useState(0);
  // Store Category in CategoryData
  const categoryData = Dete.map((value) => {
    return value.category;
  });
  ////////////

  const tabsData = ["all", ...new Set(categoryData)];

  // for all categoryData set
  const filterCategory = (category) => {
    if (category == "all") {
      setData(Dete);
      return;
    }
    const filteredData = Dete.filter((value) => {
      return value.category == category;
    });

    setData(filteredData);
  };
  return (
    <div>
      <section className='Product-Section1 '>
        <div className='Product-Section-Text'>
          <Fade left>
            <h1>OUR Products</h1>
          </Fade>
          <Fade right>
            <p> Security of Supply defines The Axia interglobal llp strategy to ensure safety, accountability, honest and long-term organic growth.      </p>
          </Fade>
        </div>

      </section>
      {/* ------------------------------------------search products-------------------- */}

      <section className="pt-70 pb-90 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="HomeSection-5-Solution-con">
                {/* <h3 className="HomeSection5-text">OUR Products</h3> */}
                <Fade left>
                  <p>Find various Products </p>
                </Fade>
              </div>
            </div>
            <div className="col-md-6">
              {/* <div className="SolutionPara">
                <p>
                  Security of Supply defines The Axia interglobal llp strategy to ensure safety, accountability, honest and long-term organic growth.      </p>
              </div> */}
            </div>
          </div>
          <div className="row pt-lg-2 pb-lg-2">
            <div className="col-md-6">
              <Zoom>
                <div className="search-box">
                  <input
                    type="search"
                    placeholder="search"
                    className="searchInput"
                    id="search_product"
                    onChange={(event) => {
                      setSearchTerm(event.target.value);
                    }}
                  />
                  {/* <button className="search_btn">
                  <i className="fa fa-search"></i>
                  </button> */}
                </div>
              </Zoom>
            </div>
            <div className="col-md-6">
              <Zoom>
                <div className="count d-flex justify-content-end">
                  <h2 onClick={handleShow}>View Products</h2>
                  {/* <input type="text" placeholder="" value="100"/>  */}
                </div>
              </Zoom>
            </div>
          </div>
          <hr />
          <div className="row">
            {Dete.filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.ServiceName.toString()
                  .toLowerCase()
                  .includes(searchTerm.toString().toLowerCase())
              ) {
                return val;
              }
            }).map((val) => {
              // Dete.map((elem) => {
              //   const { id, ServiceImg, ServicePara1, ServiceName } = elem;

              return (
                <>
                  <div
                    className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
                    key={val.id}
                  >
                    <div className="wsk-cp-product">
                      <div className="wsk-cp-img">
                        <Swiper
                          pagination={{ clickable: true }}
                          navigation={true}
                          modules={[Autoplay, Navigation, Pagination]}
                          className="projSwiper"
                        >
                          {val.imgData.map((imgValue) => (
                            <SwiperSlide>
                              <img src={imgValue.productImg}></img>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </div>
                    <div className="wsk-cp-text">
                      {/* <div className="category">

                            </div> */}
                      <div className="title-product">
                        <h3>{val.ServiceName}</h3>
                        <span>
                          <a
                            href={val.ServicePara1}
                            target="_blank"
                            rel="noreferrer"
                          >
                            view details
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
      {/* <div className="programButton">
    <a className="programButtonClick" >Book Now</a>
</div> */}

      <Modal
        show={show}
        id="partner"
        backdrop="static"
        keyboard={false}
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onHide={handleClose}
      >
        <Modal.Body>
          <div className="partnerProgramForm">
            <div className="formTitleSection">
              <div className="closeProgram closeProgram1" onClick={handleClose}>
                <i className="fa fa-times" />{" "}
              </div>
            </div>
            <a href={prodList} target="_blank"><img src={prodList} /></a>
          </div>
        </Modal.Body>
      </Modal>

      <Footer />
    </div>
  );
};

export default ProductData;
import React from "react";
import { Link } from "react-router-dom";
import "../CSS/Footer.css";
import FooterLogo from "../Images/logo-inverse1.png";
import { HiMail } from "react-icons/hi";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import {
  RiWhatsappLine,
  RiFacebookFill,
  RiInstagramFill,
} from "react-icons/ri";

const Footer = () => {
  return (
    <>
      <section className="Footer-Section">
        <div className="container">
          <div className="Footer-con">
            <div className="row">
              <div className="col-md-4">
                <div className="Footer-Logo-con">
                  <div className="Footer-Image">
                  <Link to="/"><img src={FooterLogo} alt="Axiaig Logo" /></Link>
                  </div>
                  <p>
                    We blend chemicals for a variety of industries, and we
                    research, test and analyze every chemical solution to ensure
                    it is 100% right every time.{" "}
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="footer-Mid-Col">
                  <div className="nav_menu-2">
                    <h3 className="footer-title">Explore</h3>
                    <div className="menu-footer-menu-container">
                      <ul className="menu-footer-menu">
                        <li className="menu-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/about-us-industrial-chemical-supplier-in-mumbai">
                            About
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/chemical-products/search">Product</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/cargo-shipping-company-in-mumbai">
                            Service
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/contact-us">Contact</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="footer-column">
                  <div className="widget">
                    <h3>Get in touch</h3>
                    <div className="footer-contact">
                      <p className="footer-contact-text">
                        Axia Interglobal LLP 11, Haresh Chambers, 3rd Floor,
                        313/319, Samuel Street, Masjid Bunder (West), Mumbai -
                        400003
                      </p>
                      <div className="footer-contact-info">
                        <p className="footer-contact-phone">
                          <span className="ti-headphone-alt">
                            <TfiHeadphoneAlt />
                          </span>
                          <a href="tel:8878879401">+91 8878879401</a>
                        </p>
                        <p className="footer-contact-mail">
                        <span className="ti-headphone-alt">
                            <HiMail />
                          </span>
                          <a href="mailto:info@axiaig.com">info@axiaig.com</a>
                        </p>
                      </div>
                      {/* <div className='Footer-Social-Media'>
                                                <RiWhatsappLine />
                                                <RiFacebookFill />
                                                <RiInstagramFill />
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Copy-Right">
            <div className="Contact-Us-Copy">
              © Copyright &nbsp;
              <strong>
                <span style={{ color: "#fff" }}>Axia Interglobal LLP</span>
              </strong>
              . All Rights Reserved
            </div>
            <div className="credits">
              Designed by
              <a href="https://skdm.in/" style={{ color: "#fff" }}>
                &nbsp;<b> Shree Krishna Digital Marketing</b>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;

import React, {useLayoutEffect }  from 'react';
import "../CSS/Service.css";
import handshake from "../Images/Logo/hand-shake.png";
import guard from "../Images/Logo/guard.png";
import guarantee from "../Images/Logo/like.png";
import Location from "../Images/Logo/pin.png";

import Advantage from "../Images/Axia  Service Image crop 01.jpg";
import AdvantageOverlay from "../Images/Service Overlay.jpg";

import PharmaceuticalIndustry from "../Images/Products/Pharmaceutical Industry.jpg";


import marketingexpertise from "../Images/Logo/marketing expertise.png";
import gameplan from "../Images/Logo/game plan.png";
import contractmanagement from "../Images/Logo/partnership.png";
import technicalsupport from "../Images/Logo/technical-support.jfif";
import Footer from '../Component/Footer';
import Bounce from 'react-reveal/Bounce'
import Fade from 'react-reveal/Fade';
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";


const Services = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <>
          {/* ========= Sco ========== */}
          <MetaTags>
        <title>Cargo Shipping Company In Mumbai | Axia Interglobal LLP</title>
        <meta name="description" content="Streamline Your Cargo Logistics with Axia Interglobal LLP. Discover Reliable Shipping Solutions for Your Business Needs Across The World." />
        <meta property="og:title" content="Cargo Shipping Company In Mumbai | Axia Interglobal LLP" />
        <meta property="og:description" content="Streamline Your Cargo Logistics with Axia Interglobal LLP. Discover Reliable Shipping Solutions for Your Business Needs Across The World." />
        <meta name="keywords" content="Cargo Shipping Company In Mumbai" />
        <link rel="canonical" href="https://axiaig.com/cargo-shipping-company-in-mumbai" />
      </MetaTags>
      {/* ======== Sco end ======= */}
      {/* ///////////////////////////// Section 1 ///////////////////////////// */}

      <section className='Service-Section1'>
        <div className='Service-Section-Text'>
        <Fade left>
          <h1>ocean shipping Service <span className='d-none'>Cargo Shipping Company In Mumbai</span></h1>
          </Fade>
          <Fade right>
          <p>This company provides ocean shipping services, delivering goods and cargo across the world with efficiency, reliability, and a commitment to safety and environmental responsibility.</p>
          </Fade>
        </div>
       
      </section>

      {/* ///////////////////////////// Section 2 ///////////////////////////// */}

      <section className='Service-Section2'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='Services-Page-Images-con'>
                <div className='Services-Page-Images'>
                  <Fade left>
                  <img src={Advantage} alt="Cargo Shipping Company In Mumbai" />
                  </Fade>
                </div>
                {/* <div className='ServiceS1-Overlay'>
                  <img src={AdvantageOverlay} alt="Cargo Shipping Company In Mumbai" />
                </div> */}
                {/* <div className='Services1-Overlay-Ex'>
                  <h2>25+</h2>
                  <h4>Years of Experience</h4>
                </div> */}
              </div>
            </div>
            <Fade right>
            <div className='col-md-6'>
              <div className='Service-Page-Advantage-details'>
                <div className='Service-Page-Advantage'>
                  <h2>Our Advantage</h2>
                </div>
                <div className='advantage-points'>

                  <div className='Advantage-con'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='advantage-Logo-con'>
                          <div className='advantage-Logo'>
                            <img src={handshake} alt="Cargo Shipping Company In Mumbai" />
                          </div>
                        </div>
                      </div>

                      <div className='col-md-8'>
                        <div className='Advantage-details'>
                          <h4>
                            we are trusted
                          </h4>
                          <p>
                          In addition to offering a wide variety of industrial chemicals and solvents, the company maintains a strong commitment to the responsible distribution of our products.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className='Advantage-con'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='advantage-Logo-con'>
                          <div className='advantage-Logo'>
                            <img src={guard} alt="Cargo Shipping Company In Mumbai" />
                          </div>
                        </div>
                      </div>

                      <div className='col-md-8'>
                        <div className='Advantage-details'>
                          <h4>
                            the best security
                          </h4>
                          <p>
                          Security of Supply defines The Axia interglobal llp strategy to ensure safety, accountability, honest and long-term organic growth.   </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className='Advantage-con'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='advantage-Logo-con'>
                          <div className='advantage-Logo'>
                            <img src={guarantee} alt="Cargo Shipping Company In Mumbai" />
                          </div>
                        </div>
                      </div>

                      <div className='col-md-8'>
                        <div className='Advantage-details'>
                          <h4>
                            100% guarantee
                          </h4>
                          <p>
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusamus accusantium asperiores deserunt itaque eum facilis
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className='Advantage-con'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='advantage-Logo-con'>
                          <div className='advantage-Logo'>
                            <img src={Location} alt="Cargo Shipping Company In Mumbai" />
                          </div>
                        </div>
                      </div>

                      <div className='col-md-8'>
                        <div className='Advantage-details'>
                          <h4>
                            quick location
                          </h4>
                          <p>
                          Axia Interglobal LLP 11, Haresh Chambers, 3rd Floor, 313/319, Samuel Street, Masjid Bunder (West), Mumbai - 400003
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </Fade>
          </div>
        </div>
      </section>


{/* ///////////////////////////// Section 5 //////////////////////////////// */}

<section className="HomeSection5">
        <div className="HomeSection5-Title">
          <div className="container">
            <div className="row">
              <Fade left>
                <div className="col-md-6">
                  <div className="HomeSection-5-Solution-con">
                    <h3 className="HomeSection5-text">OUR SOLUTIONS</h3>
                    <p>We are Provide Best Service</p>
                  </div>
                </div>
              </Fade>
              <div className="col-md-6">
                <div className="SolutionPara">
                  <Fade right>
                    <p>
                      If you want a partner that gets everything right – from the
                      formulas, to the blending, to the packaging and labels, to the
                      correct permits, to shipping it to the right destination on
                      time every time – axia Chemical is the only name you need to
                      know in Mumbai.{" "}
                    </p>
                  </Fade>
                </div>
              </div>
            </div>


            <div className="solution_section">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 mb-3-mt-3">
                    <div className="servi_card-1">
                      <div className="servi-card-img">
                        <Fade right>
                          <img src={marketingexpertise} alt="Cargo Shipping Company In Mumbai" />
                        </Fade>
                      </div>
                    </div>
                  </div>
                  <Fade left>
                    <div className="col-md-8 mb-5 mt-5">
                      <div className="serv_card-title">
                        <div>
                          <h5>Sustainable Development</h5>
                        </div>
                        <div className="serv_card-desc">
                          <p>We strive to balance environmental considerations and economic competitiveness.</p>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div><hr/>
                <div className="row">
                  <Fade left>
                    <div className="col-md-8 mb-5 mt-5 order-md-1 order-2">
                      <div className="serv_card-title">
                        <div>
                          <h5>Superior Products</h5>
                        </div>
                        <div className="serv_card-desc">
                          <p>Ingredients that take your brand to the next level</p>
                        </div>
                      </div>
                    </div>
                  </Fade>
                  <div className="col-md-4 mb-3-mt-3 order-md-2 order-1">
                    <div className="servi_card-1">
                      <div className="servi-card-img">
                        <Fade right>
                          <img src={gameplan} alt="Cargo Shipping Company In Mumbai" />
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div><hr/>

                <div className="row">
                  <Fade right>
                    <div className="col-md-4 mb-3-mt-3">
                      <div className="servi_card-1">
                        <div className="servi-card-img">
                          <img src={contractmanagement} alt="Cargo Shipping Company In Mumbai" />
                        </div>
                      </div>
                    </div>
                  </Fade>
                  <Fade right>
                    <div className="col-md-8 mb-5 mt-5">
                      <div className="serv_card-title">
                        <div>
                          <h5>CSR</h5>
                        </div>
                        <div className="serv_card-desc">
                          <p>Deeply committed to sustainability in every part of our global operations.</p>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div><hr/>

                <div className="row">
                  <Fade right>
                    <div className="col-md-8 mb-3 mt-5 order-md-1 order-2">
                      <div className="serv_card-title">
                        <div>
                          <h5>Total Support</h5>
                        </div>
                        <div className="serv_card-desc">
                          <p>Customizing ingredients according to your requirements Conducting evaluations of safety and efficacy</p>
                        </div>
                      </div>
                    </div>
                  </Fade>
                  <Fade left>
                    <div className="col-md-4 mb-3-mt-3 order-md-2 order-1">
                      <div className="servi_card-1">
                        <div className="servi-card-img">
                          <img src={technicalsupport} alt="Cargo Shipping Company In Mumbai" />
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div><hr/>
              </div>
            </div>
          </div>

          {/* <div className="HomeSection5-points">
            <div className="row">
              <div className="col-md-3">
                <div class="cards">
                  <div class="card">
                    <span class="close"></span>
                    <span class="arrow"></span>
                    <article>
                      <h2>Marketing Expertize</h2>
                      <div class="pic">
                        <img src={marketingexpertise} alt="Cargo Shipping Company In Mumbai" />
                      </div>
                      <div class="desc">
                        Axia interglobal llp custom designed Market
                        Investigation provides customers and suppliers alike
                        with confidential, cost-effective solutions addressing
                        needs to include: product investigation, comprehensive
                        market analysis, new product introduction, or market
                        penetration.
                      </div>
                    </article>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div class="cards">
                  <div class="card">
                    <span class="close"></span>
                    <span class="arrow"></span>
                    <article>
                      <h2>Skin In The Game</h2>
                      <div class="pic">
                        <img src={gameplan} alt="Cargo Shipping Company In Mumbai" />
                      </div>
                      <div class="desc">
                        Elijah has collected bugs since they were six years old
                        and now has many dozen bugs but none in their pants.
                      </div>
                    </article>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div class="cards">
                  <div class="card">
                    <span class="close"></span>
                    <span class="arrow"></span>
                    <article>
                      <h2>Long Term Contract Management</h2>
                      <div class="pic">
                        <img src={contractmanagement} alt="Cargo Shipping Company In Mumbai" />
                      </div>
                      <div class="desc">
                        Axia has created long term contract to our customers. We
                        also support Supplier Contractual Distribution
                        activities and requirements.
                      </div>
                    </article>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div class="cards">
                  <div class="card">
                    <span class="close"></span>
                    <span class="arrow"></span>
                    <article>
                      <h2>Export Support</h2>
                      <div class="pic">
                        <img src={technicalsupport} alt="Cargo Shipping Company In Mumbai" />
                      </div>
                      <div class="desc">
                        Axia has created long term contract to our customers. We
                        also support Supplier Contractual Distribution
                        activities and requirements.
                      </div>
                    </article>
                  </div>
                </div>
              </div>
              </div>
          </div> */}

        </div>
      </section>


      <Footer />

    </>
  )
}

export default Services
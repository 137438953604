import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// Pages 
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import NavBar from './Component/NavBar';
import Product from './Component/Product';
import ProductData from './Pages/Data';
import ErrorPage from './Pages/ErrorPage';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/cargo-shipping-company-in-mumbai' element={<Services />} />
          {/* <Route path='/Product' element={<Product/>}/> */}
          <Route path='/about-us-industrial-chemical-supplier-in-mumbai' element={<AboutUs />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path="/chemical-products/:urlType" element={<ProductData/>}/>
          <Route path="/*" element={<ErrorPage/>}/>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import "../CSS/Home.css";
import Footer from "../Component/Footer";
import React, { useRef, useState, useLayoutEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination, FreeMode } from "swiper";

import AboutUS from "../Images/container-ship-mts-loading-2.jpg";

// Logo
import Marketing from "../Images/Logo/insight.png";
import Industry from "../Images/Logo/chain.png";
import Exporting from "../Images/Logo/contract management.png";

// Service Image

import SerImage01 from "../Images/Marketing Insights.jpg";
import ImportExport from "../Images/import export.jpg";
import ChemicalIndustry from "../Images/chemical-industry.jpg";

import marketingexpertise from "../Images/Logo/marketing expertise.png";
import gameplan from "../Images/Logo/game plan.png";
import contractmanagement from "../Images/Logo/partnership.png";
import technicalsupport from "../Images/Logo/technical-support.png";
import { Link } from "react-router-dom";

// import AnilDoshi from "../Images/Team/Anil Doshi.png";
// import DhavalDoshi from "../Images/Team/Dhaval Doshi.png";
// import JayDoshi from "../Images/Team/Jay Doshi.png";
// import PragnaDoshi from "../Images/Team/Pragna Doshi.png";

///////// Key Logo //////////////

import AcS from "../Images/Key Images/Ac-S.png";
import BeroeA from "../Images/Key Images/Beroe-A.png";
import DuanmaS from "../Images/Key Images/Duanma-S.png";
import FloridA from "../Images/Key Images/Florid-A.png";
import onebloodA from "../Images/Key Images/oneblood-A.png";
import Reliance from "../Images/Key Images/Reliance.png";

import madeinindia from "../Images/Key Images/made-in-india Logo.png";
import Setting from "../Images/Logo/setting.png"
import achor from "../Images/anchor.png";

import Mission from "../Images/Mission.png";
import Vision from "../Images/vision.png";
import punjab from "../Images/brands/pidilite.png";
import asian from "../Images/brands/basf.png";
import arti from "../Images/brands/arti.png";
import exxonmylan from "../Images/brands/ExxonMobil.png";
import gacl from "../Images/brands/gacl.png";
import shk from "../Images/brands/shkkelkar.png";


import { Navigation } from "swiper";
import "swiper/css/navigation";
import "swiper/css";
import Fade from 'react-reveal/Fade';
import { MetaTags } from "react-meta-tags";

const Home = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <>
          {/* ========= Sco ========== */}
          <MetaTags>
        <title>Bulk Chemicals Supplier In Mumbai | Axia Interglobal LLP</title>
        <meta name="description" content="Excellence of Axia Interglobal LLP, Your Top-notch Bulk Chemicals Supplier in Mumbai. Elevate Business with Quality Chemical Solutions Today!" />
        <meta property="og:title" content="Bulk Chemicals Supplier In Mumbai | Axia Interglobal LLP" />
        <meta property="og:description" content="Excellence of Axia Interglobal LLP, Your Top-notch Bulk Chemicals Supplier in Mumbai. Elevate Business with Quality Chemical Solutions Today!" />
        <meta name="keywords" content="Bulk Chemicals Supplier In Mumbai" />
        <link rel="canonical" href="https://axiaig.com" />
      </MetaTags>
      {/* ////////////////////// Section 1 Banner ///////////////////////  */}
      <section className="HomeSection1">
        <div className="HomeSectionBanner">
          <Swiper
            direction={"vertical"}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="HomeBanner01">
                <div className="BannerText-con">
                  <div className="BannerText">
                    <h1 className="d-none">Bulk Chemicals Supplier In Mumbai</h1>
                    <Fade left>
                      <h2>A MULTITUDE OF CHEMICALS AT YOUR DISPOSAL</h2>
                    </Fade>
                    <Fade right>
                      <p>Since 2018</p>
                    </Fade>
                    <div className="Home-Banner-Btn">
                      <Fade bottom>
                        <Link to='/chemical-products/search'>
                          <button>Search Products</button>
                        </Link>
                      </Fade>
                    </div>

                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="HomeBanner02">
                <div className="BannerText-con">
                  <div className="BannerText">
                    <Fade left>
                      <h2>A MULTITUDE OF CHEMICALS AT YOUR DISPOSAL</h2>
                    </Fade>
                    <Fade right>
                      <p>Since 2018</p>
                    </Fade>
                    <div className="Home-Banner-Btn">
                      <Fade bottom>
                        <Link to='/chemical-products/search'>
                          <button>Search Products</button>
                        </Link>
                      </Fade>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="HomeBanner03">
                <div className="BannerText-con">
                  <div className="BannerText">
                    <Fade left>
                      <h2>A MULTITUDE OF CHEMICALS AT YOUR DISPOSAL</h2>
                    </Fade>
                    <Fade right>
                      <p>Since 2018</p>
                    </Fade>
                    <div className="Home-Banner-Btn">
                      <Fade bottom>
                        <Link to='/chemical-products/search'>
                          <button>Search Products</button>
                        </Link>
                      </Fade>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      {/* ////////////////////////// Section 2 //////////////////////// */}

      <section className="Home-Section-2">
        <div className="container">
          <div className="Home-AboutUs">
            <div className="row">
              <div className="col-md-6 order-2">
                <div className="Home-AboutUs-details">
                  <div className="Home-AboutUs-detials-con">
                    <Fade left>
                      <h6>About Us</h6>
                    </Fade>
                    <Fade left>
                      <h2>Axia Interglobal LLP</h2>
                    </Fade>
                    <Fade left>
                      <h4>
                        Running a successful <br></br>Business Since 2018
                      </h4>
                    </Fade>
                    <Fade left>
                      <p>
                        Since 2018 ,Axia interglobal LLP have been providing
                        specialised service for all the chemical needs of their
                        loyal customers. We constantly provide expert advice and
                        accurate, dependable custom chemical services for
                        customers across a variety of categories.
                      </p>
                    </Fade>
                    <div className="AboutUS-button">
                      <Fade left>
                        <Link to='/about-us-industrial-chemical-supplier-in-mumbai'>
                          <button>Know More</button>
                        </Link>
                      </Fade>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 order-1">
                <div className="HomeAbout-ImageCon">
                  <div className="Home-About-Image">
                    <Fade right>
                      <img src={AboutUS} alt="Bulk Chemicals Supplier In Mumbai" />
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* //////////////////////// Added Section 8 //////////////////////// */}

      <section className="Home-AddSection8">
        <div className="Lifts-Section">
          <div className="Lift-Section-Details-con">
            <div className="Lift-Section-Details">
              <div className="Lift-Boat-text-con">
                <div className="LiftBoat-mid-con">
                  <div className="anchor-Logo-Con">
                    <div className="anchor-Logo">
                      <img src={achor} alt="Bulk Chemicals Supplier In Mumbai" />
                    </div>
                  </div>
                  <Fade left>
                    <h2>
                      A rising tide lifts
                      <br />
                      all boats
                    </h2>
                  </Fade>
                  <Fade left>
                    <h3>JOHN F. KENNEDY</h3>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ///////////////////////// Section 4 ////////////////////// */}

      <section className="About-Section4">
        <div className="container">
          <div className="AboutUS-Section4-con">
            <div className="row">
              <div className="col-md-6 order-md-1 order-2">
                {/* <div className="Mission-Logo-con">
                  <div className="Mission-Logo">
                    <img src={Mission} alt="Bulk Chemicals Supplier In Mumbai" />
                  </div>
                </div> */}
                <Fade left>
                  <div className="Mission-MainCon">
                    <div className="Misiion-Con">
                      <div className="MIssion-In-Con">
                        <div className="Mission-plate">
                          <h3>Mission</h3>
                        </div>
                        <p>
                          Our core values revolve around providing quality chemicals, building lasting relationships, and delivering outstanding customer service. We are committed to meeting the needs of our customers by providing safe and effective products, building strong partnerships, and ensuring prompt, reliable service. By upholding these values, we strive to make a positive impact on our industry and the world. Trust us to be your reliable partner in chemistry.
                        </p>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>

              <Fade right>
                <div className="col-md-6 order-md-2 order-1">
                  <div className="Mission-Logo-con">
                    <div className="Mission-Logo">
                      <img src={Mission} alt="Bulk Chemicals Supplier In Mumbai" />
                    </div>
                  </div>
                  {/* <div className='Mission-MainCon'>
                  <div className='Misiion-Con'>
                    <div className="MIssion-In-Con">
                      <div className="Mission-plate">
                        <h3>Vision</h3>
                      </div>
                      <p>
                      To advance the business of our suppliers and customers while progressing quality of life for all through their products.</p>
                    </div>
                  </div>
                </div> */}
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>



      {/* /////////////////////////////////Section 4 //////////////////////////// */}

      <section className="About-Section41">
        <div className="container">
          <div className="AboutUS-Section4-con">
            <div className="row">
              <div className="col-md-6 ">
                <Fade right>
                  <div className="Mission-Logo-con">
                    <div className="Mission-Logo">
                      <img src={Vision} alt="Bulk Chemicals Supplier In Mumbai" />
                    </div>
                  </div>
                </Fade>
                {/* <div className='Mission-MainCon'>
                  <div className='Misiion-Con'>
                    <div className="MIssion-In-Con">
                      <div className="Mission-plate">
                        <h3>Mission</h3>
                      </div>
                      <p>
                      We focus on providing quality chemicals, building lasting relationships and delivering on our outstanding customer service.</p>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="col-md-6">
                {/* <div className="Mission-Logo-con">
                  <div className="Mission-Logo">
                    <img src={Vision} alt="Bulk Chemicals Supplier In Mumbai" />
                  </div>
                </div> */}
                <Fade left>
                  <div className="Mission-MainCon">
                    <div className="Misiion-Con">
                      <div className="MIssion-In-Con">
                        <div className="Mission-plate">
                          <h3>Vision</h3>
                        </div>
                        <p>
                          Our goal is to advance the business of our suppliers and customers while improving the quality of life for all through the use of their products. We believe that by working together, we can create a better world for everyone. We're committed to providing safe and effective chemicals, building strong partnerships, and delivering exceptional customer service to help our customers achieve their goals. Let us be your trusted partner in chemistry for a brighter future.
                        </p>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* //////////////////////////////// Section 4 //////////////////////////// */}
      <section className="HomeSection4">
        <div className="HomeSection-title">
          <Fade left>
            <h3>Problems</h3>
          </Fade>
          <div className="HomeSection-title-Para">
            <Fade right>
              <p>
                Across multiple key industries, the rise in production of
                chemicals have increased, yet there is a rise in foreign imports
                alongside. This in turn would create a dependency which will
                hamper the Indian economy and self reliance in the long term.
              </p>
            </Fade>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="HomeCard-2">
                <div className="HomeCard-2-con">
                  <Fade left>
                    <div className="HomeCard-2-Image">
                      <img src={SerImage01} alt="Bulk Chemicals Supplier In Mumbai" />
                    </div>
                  </Fade>
                </div>
                <Fade bottom>
                  <div className="HomeCard-2-Name">
                    <h4 className="Homecard-2-text">Marketing Insights</h4>
                  </div>
                </Fade>
              </div>
            </div>

            <div className="col-md-4">
              <div className="HomeCard-2">
                <div className="HomeCard-2-con">
                  <Fade left>
                    <div className="HomeCard-2-Image">
                      <img src={"https://thumbs.dreamstime.com/b/transportation-import-export-logistics-concept-container-truck-ship-port-freight-cargo-plane-transport-import-209455403.jpg"} alt="Bulk Chemicals Supplier In Mumbai" />
                    </div>
                  </Fade>
                </div>
                <div className="HomeCard-2-Name">
                  <Fade bottom>
                    <h4 className="Homecard-2-text">Industry connections</h4>
                  </Fade>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="HomeCard-2">
                <div className="HomeCard-2-con">
                  <div className="HomeCard-2-Image">
                    <Fade left>
                      <img src={ImportExport} alt="Bulk Chemicals Supplier In Mumbai" />
                    </Fade>
                  </div>
                </div>
                <div className="HomeCard-2-Name">
                  <Fade bottom>
                    <h4 className="Homecard-2-text">Exporting Challenges</h4>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* //////////////////////////////// Section 3 //////////////////////////// */}

      <section className="HomeSection3 mb-5">
        <div className="container">
          <div className="HomeSection3-title">
            <Fade top>
              <h3>Our Services</h3>
            </Fade>
          </div>
          <div className="container">
            <div className="Problems-Con">
              <div className="row">
                {/* //////// */}

                <div className="col-md-4">
                  <div className="Card-Main-con">
                    <div className="Card-Body-con">
                      <div className="Card-logo-con">
                        <div className="Card-Logo">
                          <Fade left>
                            <img src={madeinindia} alt="Chemicals Supplier In Mumbai" />
                          </Fade>
                        </div>
                        <div className="Card-Back"></div>
                      </div>
                      <div className="Card-Name">
                        <Fade bottom>
                          <h4>Promoting Indian Made Products</h4>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="Card-Main-con">
                    <div className="Card-Body-con">
                      <div className="Card-logo-con">
                        <div className="Card-Logo">
                          <Fade left>
                            <img src={Industry} alt="Chemicals Supplier In Mumbai" />
                          </Fade>
                        </div>
                        <div className="Card-Back"></div>
                      </div>
                      <div className="Card-Name">
                        <Fade bottom>
                          <h4>Key Partnerships</h4>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="Card-Main-con">
                    <div className="Card-Body-con">
                      <div className="Card-logo-con">
                        <div className="Card-Logo">
                          <Fade left>
                            <img src={Exporting} alt="Chemicals Supplier In Mumbai" />
                          </Fade>
                        </div>
                        <div className="Card-Back"></div>
                      </div>
                      <div className="Card-Name">
                        <Fade bottom>
                          <h4>Long Term Contract Management</h4>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /////// */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ///////////////////////////// Section 5 //////////////////////////////// */}

      <section className="HomeSection5">
        <div className="HomeSection5-Title">
          <div className="container">
            <div className="row">
              <Fade left>
                <div className="col-md-6">
                  <div className="HomeSection-5-Solution-con">
                    <h3 className="HomeSection5-text">OUR SOLUTIONS</h3>
                    <p>We are Provide Best Service</p>
                  </div>
                </div>
              </Fade>
              <div className="col-md-6">
                <div className="SolutionPara">
                  <Fade right>
                    <p>
                      If you want a partner that gets everything right – from the
                      formulas, to the blending, to the packaging and labels, to the
                      correct permits, to shipping it to the right destination on
                      time every time – axia Chemical is the only name you need to
                      know in Mumbai.{" "}
                    </p>
                  </Fade>
                </div>
              </div>
            </div>


            <div className="solution_section">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 mb-3-mt-3">
                    <div className="servi_card-1">
                      <div className="servi-card-img">
                        <Fade right>
                          <img src={marketingexpertise} alt="Chemicals Supplier In Mumbai" />
                        </Fade>
                      </div>
                    </div>
                  </div>
                  <Fade left>
                    <div className="col-md-8 mb-5 mt-5">
                      <div className="serv_card-title">
                        <div>
                          <h5>Sustainable Development</h5>
                        </div>
                        <div className="serv_card-desc">
                          <p>We strive to balance environmental considerations and economic competitiveness.</p>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div><hr />
                <div className="row">
                  <Fade left>
                    <div className="col-md-8 mb-5 mt-5 order-md-1 order-2">
                      <div className="serv_card-title">
                        <div>
                          <h5>Superior Products</h5>
                        </div>
                        <div className="serv_card-desc">
                          <p>Ingredients that take your brand to the next level</p>
                        </div>
                      </div>
                    </div>
                  </Fade>
                  <div className="col-md-4 mb-3-mt-3 order-md-2 order-1">
                    <div className="servi_card-1">
                      <div className="servi-card-img">
                        <Fade right>
                          <img src={gameplan} alt="Chemicals Supplier In Mumbai" />
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div><hr />

                <div className="row">
                  <Fade right>
                    <div className="col-md-4 mb-3-mt-3">
                      <div className="servi_card-1">
                        <div className="servi-card-img">
                          <img src={contractmanagement} alt="Chemicals Supplier In Mumbai" />
                        </div>
                      </div>
                    </div>
                  </Fade>
                  <Fade right>
                    <div className="col-md-8 mb-5 mt-5">
                      <div className="serv_card-title">
                        <div>
                          <h5>CSR</h5>
                        </div>
                        <div className="serv_card-desc">
                          <p>Deeply committed to sustainability in every part of our global operations.</p>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div><hr />

                <div className="row">
                  <Fade right>
                    <div className="col-md-8 mb-5 mt-5 order-md-1 order-2">
                      <div className="serv_card-title">
                        <div>
                          <h5>Total Support</h5>
                        </div>
                        <div className="serv_card-desc">
                          <p>Customizing ingredients according to your requirements Conducting evaluations of safety and efficacy.</p>
                        </div>
                      </div>
                    </div>
                  </Fade>
                  <Fade left>
                    <div className="col-md-4 mb-3-mt-3 order-md-2 order-1">
                      <div className="servi_card-1">
                        <div className="servi-card-img">
                          <img src={technicalsupport} alt="Chemicals Supplier In Mumbai" />
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>






          {/* <div className="HomeSection5-points">
            <div className="row">
              <div className="col-md-3">
                <div class="cards">
                  <div class="card">
                    <span class="close"></span>
                    <span class="arrow"></span>
                    <article>
                      <h2>Marketing Expertize</h2>
                      <div class="pic">
                        <img src={marketingexpertise} alt="Bulk Chemicals Supplier In Mumbai" />
                      </div>
                      <div class="desc">
                        Axia interglobal llp custom designed Market
                        Investigation provides customers and suppliers alike
                        with confidential, cost-effective solutions addressing
                        needs to include: product investigation, comprehensive
                        market analysis, new product introduction, or market
                        penetration.
                      </div>
                    </article>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div class="cards">
                  <div class="card">
                    <span class="close"></span>
                    <span class="arrow"></span>
                    <article>
                      <h2>Skin In The Game</h2>
                      <div class="pic">
                        <img src={gameplan} alt="Bulk Chemicals Supplier In Mumbai" />
                      </div>
                      <div class="desc">
                        Elijah has collected bugs since they were six years old
                        and now has many dozen bugs but none in their pants.
                      </div>
                    </article>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div class="cards">
                  <div class="card">
                    <span class="close"></span>
                    <span class="arrow"></span>
                    <article>
                      <h2>Long Term Contract Management</h2>
                      <div class="pic">
                        <img src={contractmanagement} alt="Bulk Chemicals Supplier In Mumbai" />
                      </div>
                      <div class="desc">
                        Axia has created long term contract to our customers. We
                        also support Supplier Contractual Distribution
                        activities and requirements.
                      </div>
                    </article>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div class="cards">
                  <div class="card">
                    <span class="close"></span>
                    <span class="arrow"></span>
                    <article>
                      <h2>Export Support</h2>
                      <div class="pic">
                        <img src={technicalsupport} alt="Bulk Chemicals Supplier In Mumbai" />
                      </div>
                      <div class="desc">
                        Axia has created long term contract to our customers. We
                        also support Supplier Contractual Distribution
                        activities and requirements.
                      </div>
                    </article>
                  </div>
                </div>
              </div>
              </div>
          </div> */}

        </div>
      </section>

      {/* //////////////////////////// Section 6 /////////////////////////////// */}

      {/* <section className="HomeSection6">
        <div className='container'>
          <div className="Home-Section6-title">
            <h4 className="home-Section6-text">
              GET IN TOUCH WITH OUR TEAM
            </h4>
          </div>
          <div className='row'>

            <div className='col-md-3'>
              <div className='Service-Card-Body'>
                <div className='Service-Card'>
                  <div className='Service-Card-Image'>
                    <img src={JayDoshi} alt="" />
                  </div>
                  <div className='Services-Card-Overlay'>
                    <div className='Profile-Card-Name'>
                      Jay Doshi
                    </div>
                    <div className='Show-Line'>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className='col-md-3'>
              <div className='Service-Card-Body'>
                <div className='Service-Card'>
                  <div className='Service-Card-Image'>
                    <img src={DhavalDoshi} alt="Bulk Chemicals Supplier In Mumbai" />
                  </div>
                  <div className='Services-Card-Overlay'>
                    <div className='Profile-Card-Name'>
                      Dhaval Doshi
                    </div>
                    <div className='Show-Line'>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-3'>
              <div className='Service-Card-Body'>
                <div className='Service-Card'>
                  <div className='Service-Card-Image'>
                    <img src={AnilDoshi} alt="Bulk Chemicals Supplier In Mumbai" />
                  </div>
                  <div className='Services-Card-Overlay'>
                    <div className='Profile-Card-Name'>
                      Anil Doshi
                    </div>
                    <div className='Show-Line'>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-3'>
              <div className='Service-Card-Body'>
                <div className='Service-Card'>
                  <div className='Service-Card-Image'>
                    <img src={PragnaDoshi} alt="Bulk Chemicals Supplier In Mumbai" />
                  </div>
                  <div className='Services-Card-Overlay'>
                    <div className='Profile-Card-Name'>
                      Pragna Doshi
                    </div>
                    <div className='Show-Line'>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* //////////////////////////// Section 7 /////////////////////////////// */}

      <section className="HomeSection7">
        <div className="container">
          <div className="HomeSection-Key-part-con">
            <div className="Home-Section-7-Swiper">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                freeMode={true}
                navigation={true}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 50,
                  },
                }}

                modules={[FreeMode, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="Key-Con">
                    <div className="Key-Con-Image">
                      <img src={AcS} alt="Bulk Chemicals Supplier In Mumbai" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Key-Con-two">
                    <div className="Key-Con-Image-second">
                      <img src={BeroeA} alt="Bulk Chemicals Supplier In Mumbai" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Key-Con-two">
                    <div className="Key-Con-Image-second">
                      <img src={FloridA} alt="Bulk Chemicals Supplier In Mumbai" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Key-Con-two">
                    <div className="Key-Con-Image-second">
                      <img src={onebloodA} alt="Bulk Chemicals Supplier In Mumbai" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Key-Con">
                    <div className="Key-Con-Image">
                      <img src={DuanmaS} alt="Bulk Chemicals Supplier In Mumbai" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Key-Con-two">
                    <div className="Key-Con-Image-second">
                      <img src={Reliance} alt="Bulk Chemicals Supplier In Mumbai" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Key-Con">
                    <div className="Key-Con-Image">
                      <img src={asian} alt="Bulk Chemicals Supplier In Mumbai" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Key-Con">
                    <div className="Key-Con-Image">
                      <img src={punjab} alt="Bulk Chemicals Supplier In Mumbai" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Key-Con">
                    <div className="Key-Con-Image">
                      <img src={arti} alt="Chemicals Supplier In Mumbai" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Key-Con">
                    <div className="Key-Con-Image">
                      <img src={exxonmylan} alt="Chemicals Supplier In Mumbai" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Key-Con">
                    <div className="Key-Con-Image">
                      <img src={gacl} alt="Chemicals Supplier In Mumbai" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Key-Con">
                    <div className="Key-Con-Image">
                      <img src={shk} alt="Chemicals Supplier In Mumbai" />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      {/* //////////////////////////////////// Section 8 ///////////////////////////////// */}

      {/* <section className="Home-Section8">
        <div className="container">
          <div className="Home-Pi-Chart-con">
            <div className="Home-Pi-Chart">

            </div>
            <div>
            </div>
          </div>
        </div>

      </section> */}

      <Footer />
    </>
  );
};

export default Home;

import React, {useLayoutEffect } from 'react';
import "../CSS/ContactUs.css";

import { BsFillPinMapFill } from 'react-icons/bs';
import { BiMailSend, BiPhoneCall } from 'react-icons/bi';
import { BsClock } from 'react-icons/bs';
// import Footer from "../Component/Footer";
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';
import $ from "jquery";
import Footer from "../Component/Footer";
import Fade from 'react-reveal/Fade';
import { MetaTags } from "react-meta-tags";


const ContactUs = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  const Condetails = {
    margin: "0px 5px",
    color:"whitesmoke",
    fontFamily:"var(---FontFour)",
    fontSize: "17px",
  }
  return (
    <>
          {/* ========= Sco ========== */}
          <MetaTags>
        <title>Contact Us | Axia Interglobal LLP</title>
        <meta name="description" content="Axia Interglobal LLP, 11, Haresh Chambers, 3rd Floor, 313/319, Samuel Street, Masjid Bunder (West), Mumbai - 400003. Phone No -  +91 8878879401" />
        <meta property="og:title" content="Contact Us | Axia Interglobal LLP" />
        <meta property="og:description" content="Axia Interglobal LLP, 11, Haresh Chambers, 3rd Floor, 313/319, Samuel Street, Masjid Bunder (West), Mumbai - 400003. Phone No -  +91 8878879401" />
        <meta name="keywords" content="Contact Us | Axia Interglobal LLP" />
        <link rel="canonical" href="https://axiaig.com/contact-us" />
      </MetaTags>
      {/* ======== Sco end ======= */}
      {/* ///////////////////////////////// section 1 ////////////////////////////////// */}

      <section className='Contact-Section-1'>
        <div className='Contact-Banner'>
          <h1>
            <Fade left>
              Contact Us
            </Fade>
          </h1>
          <Fade right>
          <p>Please feel free to contact us through the provided channels for any inquiries or assistance you may need.</p>
          </Fade>
        </div>
      </section>

      {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

      <section className='Contact-Section-2'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4' style={{ margin: "0px", padding: "0px" }}  >
              <div className='Contact-Information'>
                <h3 className='Contact-Information-h3'>
                  <Bounce left cascade>
                    Contact Us
                  </Bounce>
                </h3>
                <div className='contact-info-con'>
                  <Zoom>
                    <div className='Contact-icon'>
                      <BiPhoneCall />
                    </div>
                  </Zoom>
                  <div className='Contact-icon-info-con'>
                    <div className='Contact-icon-info'>
                      <p><strong style={Condetails}>Phone:<br/></strong>+91 8878879401</p>
                    </div>
                  </div>
                </div>

                <div className='contact-info-con'>
                  <Zoom>
                    <div className='Contact-icon'>
                      <BiMailSend />
                    </div>
                  </Zoom>
                  <div className='Contact-icon-info-con'>
                    <div className='Contact-icon-info'>
                      <p><strong style={Condetails}>Email:<br/></strong>info@axiaig.com</p>
                    </div>
                  </div>
                </div>

                <div className='contact-info-con'>
                  <Zoom>
                    <div className='Contact-icon'>
                      <BsClock />
                    </div>
                  </Zoom>
                  <div className='Contact-icon-info-con'>
                    <div className='Contact-icon-info'>
                      <p><strong style={Condetails}>Office Hours:<br/></strong>Monday-Saturday:10.30am-7.00pm
<br/>Sunday - CLOSED</p>
                    </div>
                  </div>
                </div>

                <div className='contact-info-con'>
                  <Zoom>
                    <div className='Contact-icon'>
                      <BsFillPinMapFill />
                    </div>
                  </Zoom>
                  <div className='Contact-icon-info-con'>
                    <div className='Contact-icon-info'>
                      <p><strong style={Condetails}>Address:</strong> Axia Interglobal LLP<br></br>
                        11, Haresh Chambers, 3rd Floor, <br></br>
                        313/319, Samuel Street, Masjid Bunder (West),
                        Mumbai - 400003</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-8' style={{ margin: "0px", padding: "0px" }}>
              <div className='Contact-Form-details'>
                <div className='Contact-form-text'>
                  <Bounce left cascade>
                    <h3>Get in touch</h3>
                  </Bounce>
                  <p>Ask me a question, I'd love to hear more from you.</p>
                </div>
                <div className='contactpage-form'>
                  <form>
                    <div className='tow-inputs'>
                      <label>
                        <input type="text" name="name" placeholder='Enter Your Name'/>
                      </label>
                      <label>
                        <input type="number" name="contact" placeholder='Company Name'/>
                      </label>
                    </div>
                    <div className='tow-inputs'>
                      <label>
                        <input type="text" name="name" placeholder='Enter Email'/>
                      </label>
                      <label>
                        <input type="number" name="contact" placeholder='Your Contact Number'/>
                      </label>
                    </div>
                    <div className='message-input'>
                      <label>
                        <textarea type="textarea" name="Message" placeholder='Your Message'/>
                      </label>
                    </div>
                    <div className='contact-btn'>
                      <button className='Sub-btn'>
                        submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='ContactUs-map mb-5'>
        <div className='container'>
          <div className='Contact-iframe'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d120618.81840524975!2d72.75480041260543!3d19.1366731842847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7ac1ba921cb%3A0xc852cf985b354903!2sWhatever%20events!5e0!3m2!1sen!2sin!4v1672137208379!5m2!1sen!2sinhttps://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7547.110936842232!2d72.8359513!3d18.9510606!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ce24452fcdb7%3A0x9a7f60557e81d38b!2sTrishulent%20India!5e0!3m2!1sen!2sin!4v1675771999674!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  )
}

export default ContactUs
import sample1 from "../doc/ACRYLIC ACID.pdf";
import sample2 from "../doc/ACETONE.pdf";
import sample3 from "../doc/ACETIC ACID.pdf";
import sample4 from "../doc/CITRIC ACID MONOHYDRATE.pdf";
import sample5 from "../doc/GLYCERINE.pdf";
import sample6 from "../doc/ISOPROPYL ALCOHOL.pdf";
import sample7 from "../doc/MALIEC ANHYDRIDE.pdf";
import sample8 from "../doc/MELAMINE.pdf";
import sample9 from "../doc/MONO ETHYLENE GLYCOL.pdf";
import sample10 from "../doc/MONOSODIUM GLUTAMATE.pdf";
import sample11 from "../doc/methanol.pdf";
import sample12 from "../doc/PHENOL.pdf";
import sample13 from "../doc/TOULENE.pdf";



import acrylic1 from "../Images/product_img/acrylic-acid.jpg";
import acrylic2 from "../Images/product_img/acrylic-acid1.jpg";
import acrylic3 from "../Images/product_img/acrylic-acid2.jpg";
import acrylic4 from "../Images/product_img/acryylic-acid.jpg";
import Citric from "../Images/product/citric-acid.jpg";
import Citric1 from "../Images/product/citric-acid-monohydrate.jpg";
import mela from "../Images/product_img/melamine.jpg";
import mela1 from "../Images/product_img/melamine1.jpg";
import mela2 from "../Images/product_img/melamine2.jpg";
import mela3 from "../Images/product_img/melamine3.jpg";
import mela4 from "../Images/product_img/melamine4.jpg";
import methal from "../Images/product_img/methanol.jpg";
import phenol from "../Images/product_img/phenol.jpg";
import phenol1 from "../Images/product_img/phenol2.jpg";
import acetone from "../Images/product/acetone.jpg";
import toluene from "../Images/product_img/toluene.jpg";
import toluene1 from "../Images/product_img/toulene1.jpg";
import maleic from "../Images/product_img/maleic-anhy.jpg";
import maleic1 from "../Images/product_img/Maleic-Anhydride.jpg";
import maleic2 from "../Images/product_img/maleic-anhy1.jpg";
import maleic3 from "../Images/product_img/maleic-anhy2.jpg";
import acetic from "../Images/product/acetic-acid.jpg";
import mono from "../Images/product_img/mono-ethe.jpg";
import mono1 from "../Images/product_img/meg1.jpg";
import glycerine from "../Images/product/glycerine.jpg";
import glycerine1 from "../Images/product_img/glycerine1.jpg";
import isop1 from "../Images/product_img/iso2.jpg";
import monos from "../Images/product_img/monosodium.jpg";
import monos1 from "../Images/product_img/monosodium-2.jpg";
import monos2 from "../Images/product_img/monosodium-3.jpg";
import monos3 from "../Images/product_img/monosodium-glu.jpg";
import monos4 from "../Images/product_img/msg1.jpg";

const Dete = [
    {
        "id": 1,
        "ServiceName": "ACRYLIC ACID",
        ServicePara1: sample1,
        "imgData": [
          {"productImg": acrylic1},
          {"productImg": acrylic2},
          {"productImg": acrylic3},
          {"productImg": acrylic4},
          
        ]
      },
    {
        "id": 2,
        "ServiceName": "ACETONE",
        ServicePara1: sample2,
        "imgData": [
          {"productImg": acetone},          
        ]
      },
    {
        "id": 3,
        "ServiceName": "ACETIC ACID",
        ServicePara1: sample3,
        "imgData": [
          {"productImg": acetic},
        ]
      },
    {
        "id": 4,
        "ServiceName": "CITRIC ACID MONOHYDRATE",
        ServicePara1: sample4,
        "imgData": [
          {"productImg": Citric},
          {"productImg": Citric1},
        ]
      },
    {
        "id": 5,
        "ServiceName": "GLYCERINE",
        ServicePara1: sample5,
        "imgData": [
          {"productImg": glycerine},
          {"productImg": glycerine1},
        ]
      },
    {
        "id": 6,
        "ServiceName": "ISOPROPYL ALCOHOL (IPA)",
        ServicePara1: sample6,
        "imgData": [
          {"productImg": isop1},
        ]
      },
    {
        "id": 7,
        "ServiceName": "MALEIC ANHYDRIDE",
        ServicePara1: sample7,
        "imgData": [
          {"productImg": maleic},
          {"productImg": maleic1},
          {"productImg": maleic2},
          {"productImg": maleic3},
          
        ]
      },
    {
        "id": 8,
        "ServiceName": "MELAMINE",
        ServicePara1: sample8,
        "imgData": [
          {"productImg": mela},
          {"productImg": mela1},
          {"productImg": mela2},
          {"productImg": mela3},
          {"productImg": mela4},
          
        ]
      },
    {
        "id": 9,
        "ServiceName": "MONOETHYLENE GLYCOL (MEG)",
        ServicePara1: sample9,
        "imgData": [
          // {"productImg": mono},
          {"productImg": mono1},
        ]
      },
    {
        "id": 10,
        "ServiceName": "MONOSODIUM GLUTAMATE (MSG)",
        ServicePara1: sample10,
        "imgData": [
          {"productImg": monos},
          {"productImg": monos1},
          {"productImg": monos2},
          {"productImg": monos3},
          {"productImg": monos4},
          
        ]
      },
    {
        "id": 11,
        "ServiceName": "METHANOL",
        ServicePara1: sample11,
        "imgData": [
          {"productImg": methal},
        ]
      },
    {
        "id": 12,
        "ServiceName": "PHENOL",
        ServicePara1: sample12,
        "imgData": [
          {"productImg": phenol},
          {"productImg": phenol1},
        ]
      },
    {
        "id": 13,
        "ServiceName": "TOLUENE",
        ServicePara1: sample13,
        "imgData": [
          {"productImg": toluene},
          {"productImg": toluene1},
        ]
      },
      
    ];

export default Dete;
import React, { useState, useEffect, useRef } from 'react';
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { CiInstagram } from 'react-icons/ci';
import { MdCall } from 'react-icons/md';
import Logo from "../Images/logo-inverse1.png";
import "../CSS/NavBar.css";

const NavBar = () => {
  const [expanded, setExpanded] = useState(false);
  const [color, setColor] = useState(false);
  const { pathname } = useLocation();
  const navbarRef = useRef(null);

  const changeColor = () => {
    if (window.scrollY >= 78) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeColor);
    document.addEventListener('click', handleDocumentClick);
    return () => {
      window.removeEventListener('scroll', changeColor);
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const handleToggle = () => {
    setExpanded(!expanded);
  }

  const handleDocumentClick = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setExpanded(false);
    }
  }

  return (
    <div className="nav-bar-component">
      <section className="NavSection">
        <div className="container">
          <Navbar
            collapseOnSelect
            expand="lg"
            variant="dark"
            className={color ? "NavBar NavBar-bg" : "NavBar"}
            expanded={expanded}
            ref={navbarRef}
          >
            <Container>
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={handleToggle}
              />
              <Navbar.Brand href="/">
                <div className='NavLogo'>
                  <img src={Logo} alt="Axiaig Logo" />
                </div>
              </Navbar.Brand>
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="justify-content-start"
              >
                <Nav>
                  <Nav.Link>
                    <Link
                      to="/"
                      className={pathname === "/" ? "active" : ""}
                      onClick={() => setExpanded(false)}
                    >
                      Home
                    </Link>
                  </Nav.Link>
                  <Nav.Link>
                    <Link
                      to="/about-us-industrial-chemical-supplier-in-mumbai"
                      className={pathname === "/about-us-industrial-chemical-supplier-in-mumbai" ? "active" : ""}
                      onClick={() => setExpanded(false)}
                    >
                      About Us
                    </Link>
                  </Nav.Link>
                  <Nav.Link>
                    <Link
                      to="/chemical-products/list"
                      className={pathname === "/chemical-products" ? "active" : ""}
                      onClick={() => setExpanded(false)}
                    >
                      Products
                    </Link>
                  </Nav.Link>
                  <Nav.Link>
                    <Link
                      to="/cargo-shipping-company-in-mumbai"
                      className={pathname === "/cargo-shipping-company-in-mumbai" ? "active" : ""}
                      onClick={() => setExpanded(false)}
                    >
                      Services
                    </Link>
                  </Nav.Link>
                  <Nav.Link>
                    <Link
                      to="/contact-us"
                      className={pathname === "/contact-us" ? "active" : ""}
                      onClick={() => setExpanded(false)}
                    >
                      Contact Us
                    </Link>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
              <Nav className="Social-media-icons">
                <Nav.Link href='tel:8878879401'>
                  <i><MdCall/></i>+91 8878879401
                </Nav.Link>
              </Nav>
            </Container>
          </Navbar>
        </div>
      </section>
    </div>
  );
};

export default NavBar;

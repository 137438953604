import React, { useLayoutEffect } from "react";
import "../CSS/About.css";
import AboutUS from "../Images/container-ship-mts-loading-2.jpg";
import handshake from "../Images/Logo/hand-shake.png";
import guard from "../Images/Logo/guard.png";
import guarantee from "../Images/Logo/like.png";
import Location from "../Images/Logo/pin.png";

import Setting from "../Images/Logo/setting.png";

import CountUp from "react-countup";

import Support from "../Images/Logo/Support.png";
import Project from "../Images/Logo/Project.png";
import vehicle from "../Images/Logo/Exporting.png";
import Client from "../Images/Logo/Clients.png";

import Footer from "../Component/Footer";
import Mission from "../Images/Mission.png";
import Vision from "../Images/vision.png";
import experience from "../Images/Logo/user-experience.png";
import global from "../Images/Logo/global.png";
import customer from "../Images/Logo/customer-service.png";
import setting from "../Images/Logo/settingo.png";
import inventory from "../Images/Logo/inventory.png";
import profits from "../Images/Logo/profits.png";

import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import $ from "jquery";
// import CountUp from 'react-countup';

const AboutUs = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  var counted = 0;
  $(window).scroll(function () {
    var oTop = $("#counter").offset().top - window.innerHeight;
    if (counted === 0 && $(window).scrollTop() > oTop) {
      $(".count").each(function () {
        var $this = $(this),
          countTo = $this.attr("data-count");
        $({
          countNum: $this.text(),
        }).animate(
          {
            countNum: countTo,
          },
          {
            duration: 5000,
            easing: "swing",
            step: function () {
              $this.text(Math.floor(this.countNum));
            },
            complete: function () {
              $this.text(this.countNum);
            },
          }
        );
      });
      counted = 1;
    }
  });
  return (
    <>
      {/* ========= Sco ========== */}
      <MetaTags>
        <title>Leading Industrial Chemical Supplier in Mumbai | Axia Interglobal LLP</title>
        <meta name="description" content="Elevate Your Industry with Premium Chemical Solutions. Discover Excellence with Axia Interglobal LLP, Your Trusted Industrial Chemical Supplier in Mumbai." />
        <meta property="og:title" content="Leading Industrial Chemical Supplier in Mumbai | Axia Interglobal LLP" />
        <meta property="og:description" content="Elevate Your Industry with Premium Chemical Solutions. Discover Excellence with Axia Interglobal LLP, Your Trusted Industrial Chemical Supplier in Mumbai." />
        <meta name="keywords" content="Leading Industrial Chemical Supplier in Mumbai" />
        <link rel="canonical" href="https://axiaig.com/about-us-industrial-chemical-supplier-in-mumbai" />
      </MetaTags>
      {/* ======== Sco end ======= */}
      {/* /////////////////////////////// Section //////////////////////////////////// */}

      <section className="AboutUS-Banner">
        <div className="AboutUS-Bannertext">
          <Fade left>
            <h1 className="d-none">Leading Industrial Chemical Supplier in Mumbai</h1>
            <h2>Axia Shipping Service</h2>
          </Fade>
          <Fade right>
            <p>
              Axia Shipping Service is a global shipping company that offers
              comprehensive and customized solutions for container shipping,
              bulk shipping, and specialized transportation, with a strong
              emphasis on customer satisfaction, safety, and innovation.
            </p>
          </Fade>
        </div>
      </section>

      {/* ////////////////////////// Section 1 ////////////////////////// */}

      {/* ////////////////////////// Section 2 //////////////////////// */}

      <section className="Home-Section-2">
        <div className="container">
          <div className="Home-AboutUs">
            <div className="row">
              <div className="col-md-6 order-2">
                <div className="Home-AboutUs-details">
                  <Fade left>
                    <div className="Home-AboutUs-detials-con">
                      <h6>About Us</h6>
                      <h2>Axia Interglobal LLP</h2>
                      <h4>
                        Running a successful <br></br>Business Since 2018
                      </h4>
                      <p>
                        Since 2018 ,Axia interglobal LLP have been providing
                        specialised service for all the chemical needs of their
                        loyal customers. With a solid financial and
                        manufacturing base built over half a century, we
                        continue to grow with confidence and strength and
                        welcome the opportunity to add you to our list of very
                        satisfied customers.
                      </p>
                      {/* <div className="AboutUS-button">
                        <button>
                          Know More
                        </button>
                      </div> */}
                    </div>
                  </Fade>
                </div>
              </div>
              <Fade right>
                <div className="col-md-6 order-1">
                  <div className="HomeAbout-ImageCon">
                    <div className="Home-About-Image">
                      <img src={AboutUS} alt="Axia interglobal LLP" />
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>

      {/* ///////////////////////// Section 2 ////////////////////// */}

      <section className="AboutSection3">
        <div className="container">
          <div className="why-choose-Us-text">
            <Fade left>
              <h3 className="WhyUs-text">WHY CHOOSE US ?</h3>
            </Fade>
          </div>
          {/* <div className='AboutUS-Section-Why-con'>
            <div className='row'>
              <div className='col-md-3'>
                <div className='WhyUs-Logo-Main-Con'>
                  <div className='About-WhyUS-Logo-con'>
                    <div className='About-WhyUS-Logo'>
                      <img src={handshake} alt="#" />
                    </div>
                  </div>
                  <div className='About-WhyUs-Text'>
                    <h4>we are trusted</h4>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='WhyUs-Logo-Main-Con'>
                  <div className='About-WhyUS-Logo-con'>
                    <div className='About-WhyUS-Logo'>
                      <img src={guard} alt="#" />
                    </div>
                  </div>
                  <div className='About-WhyUs-Text'>
                    <h4>the best security</h4>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='WhyUs-Logo-Main-Con'>
                  <div className='About-WhyUS-Logo-con'>
                    <div className='About-WhyUS-Logo'>
                      <img src={guarantee} alt="#" />
                    </div>
                  </div>
                  <div className='About-WhyUs-Text'>
                    <h4>100% guarantee</h4>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='WhyUs-Logo-Main-Con'>
                  <div className='About-WhyUS-Logo-con'>
                    <div className='About-WhyUS-Logo'>
                      <img src={Location} alt="#" />
                    </div>
                  </div>
                  <div className='About-WhyUs-Text'>
                    <h4>quick location</h4>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="whychoose_us_section mt-5">
            <div className="container">
              <div className="row">
                <Fade left>
                  <div className="col-md-4">
                    <div className="servi-card-img1">
                      <img src={experience} alt="skill infrastructure" />
                    </div>
                  </div>
                </Fade>
                <Fade right>
                  <div className="col-md-8">
                    <div className="Whychoose_title">
                      <h5>
                        40+ Years of family expertise and skill infrastructure
                      </h5>
                    </div>
                    <div className="Whychoose_desc">
                      <p>
                        With over 40 years of family expertise and a skilled
                        infrastructure, this company has established itself as a
                        leader in their industry. They bring a wealth of
                        knowledge and experience to every project, ensuring that
                        each client receives exceptional service and
                        high-quality results. Their team of experts is committed
                        to staying up-to-date with the latest industry trends
                        and technologies, and they work tirelessly to provide
                        innovative solutions that meet their clients' evolving
                        needs. This commitment to excellence has earned them a
                        reputation for being a trusted partner for businesses
                        across various sectors.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <hr />

              <div className="row">
                <Fade right>
                  <div className="col-md-8 mt-5 order-md-1 order-2">
                    <div className="Whychoose_title">
                      <h5>
                        Global Outreach a legacy continued through generations
                      </h5>
                    </div>
                    <div className="Whychoose_desc">
                      <p>
                        The legacy of global outreach is one that has been
                        continued through generations, with a commitment to
                        making a positive impact on the world. From
                        philanthropic endeavors to innovative solutions, this
                        legacy has paved the way for a brighter future. With a
                        focus on sustainability, social responsibility, and
                        innovation, this company has continued this legacy,
                        building a reputation for excellence and dedication to
                        making a difference. Their work has touched countless
                        lives and will continue to do so for generations to
                        come.
                      </p>
                    </div>
                  </div>
                </Fade>
                <Fade left>
                  <div className="col-md-4 order-md-2 order-1 mt-5">
                    <div className="servi-card-img1">
                      <img src={global} alt="Leading Industrial Chemical Supplier in Mumbai" />
                    </div>
                  </div>
                </Fade>
              </div>
              <hr />

              <div className="row">
                <Fade left>
                  <div className="col-md-4 mt-5">
                    <div className="servi-card-img1">
                      <img src={customer} alt="Leading Industrial Chemical Supplier in Mumbai" />
                    </div>
                  </div>
                </Fade>
                <Fade right>
                  <div className="col-md-8 mt-5">
                    <div className="Whychoose_title">
                      <h5>Timely supply long lasting customer relations</h5>
                    </div>
                    <div className="Whychoose_desc">
                      <p>
                        This company values timely supply and understands the
                        importance of building long-lasting customer
                        relationships. They strive to meet and exceed customer
                        expectations by providing reliable and efficient
                        services that are delivered on time. Through consistent
                        communication and personalized attention, they have
                        built a strong reputation for fostering customer loyalty
                        and trust. With a focus on customer satisfaction and a
                        commitment to quality, this company ensures that their
                        customers receive the best possible experience, leading
                        to lasting relationships that benefit both parties for
                        years to come.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <hr />

              <div className="row">
                <Fade right>
                  <div className="col-md-8 mt-5 order-md-1 order-2">
                    <div className="Whychoose_title">
                      <h5>Efficient Services</h5>
                    </div>
                    <div className="Whychoose_desc">
                      <p>
                        This company prides itself on delivering efficient
                        services to their customers. They understand the
                        importance of time and strive to provide fast and
                        reliable solutions that meet their customers' needs. By
                        leveraging technology and skilled professionals, they
                        are able to streamline processes and reduce wait times,
                        making sure that their customers' expectations are
                        exceeded. This commitment to efficiency has earned them
                        a reputation for excellence and has helped them to build
                        lasting relationships with their clients.
                      </p>
                    </div>
                  </div>
                </Fade>
                <Fade left>
                  <div className="col-md-4 order-md-2 order-1 mt-5 ">
                    <div className="servi-card-img1">
                      <img src={setting} alt="Leading Industrial Chemical Supplier in Mumbai" />
                    </div>
                  </div>
                </Fade>
              </div>
              <hr />

              <div className="row">
                <Fade left>
                  <div className="col-md-4 mt-5 ">
                    <div className="servi-card-img1">
                      <img src={inventory} alt="Leading Industrial Chemical Supplier in Mumbai" />
                    </div>
                  </div>
                </Fade>
                <Fade right>
                  <div className="col-md-8 mt-5 ">
                    <div className="Whychoose_title">
                      <h5>Large inventory</h5>
                    </div>
                    <div className="Whychoose_desc">
                      <p>
                        This company boasts a large inventory of products,
                        ensuring that their customers have access to a wide
                        range of options to meet their needs. From raw materials
                        to finished products, they have a comprehensive
                        selection of items readily available for purchase. With
                        a focus on quality and affordability, they take great
                        care in selecting the best products from trusted
                        suppliers, ensuring that their inventory is always
                        up-to-date and of the highest quality. This allows them
                        to fulfill orders quickly and efficiently, providing
                        their customers with the convenience of a one-stop-shop
                        for all their needs.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <hr />

              <div className="row">
                <Fade right>
                  <div className="col-md-8 mt-5 order-md-1 order-2">
                    <div className="Whychoose_title">
                      <h5>Cost efficient</h5>
                    </div>
                    <div className="Whychoose_desc">
                      <p>
                        This company is committed to providing cost-efficient
                        solutions to their customers. They understand the
                        importance of delivering value, and strive to offer
                        products and services at competitive prices without
                        compromising on quality. By optimizing their processes
                        and leveraging technology, they are able to reduce
                        overhead costs and pass the savings onto their
                        customers. This commitment to cost efficiency has earned
                        them a reputation for being a reliable and affordable
                        option for businesses of all sizes.
                      </p>
                    </div>
                  </div>
                </Fade>
                <Fade left>
                  <div className="col-md-4 mt-5 order-md-2 order-1">
                    <div className="servi-card-img1">
                      <img src={profits} alt="Leading Industrial Chemical Supplier in Mumbai" />
                    </div>
                  </div>
                </Fade>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </section>

      {/* ///////////////////////// Section 5 ////////////////////// */}

      <div class="countPanel">
        <div class="countingText">
          <div class="container">
            <div class="row d-flex justify-content-center">
              <div
                class="col-md-6 d-flex justify-content-center text-center d-md-block flex-column aos-init aos-animate"
                data-aos="fade-right"
              >
                <h2>Axia Shipping Service is a global shipping company</h2>
                <p>
                  There are some few words from our customers that are satisfied
                  with our work and our services.
                </p>
                <Link to="/contact-us">
                  <button class="btn">
                    Call Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="panelCounter">
          <ul id="counter">
            <li>
              {/* <span class="material-symbols-outlined">calendar_month </span> */}
              <span class="countingClass">
                <CountUp end={200} duration={5} />
                <span>+</span>
              </span>
              <span>Products Supllied</span>
            </li>
            <li>
              {/* <span class="material-symbols-outlined">group </span> */}
              <span class="countingClass">
                <CountUp end={27000} duration={8} />
                <span>+</span>
              </span>
              <span>Tons Quantity Supplied</span>
            </li>
            <li>
              {/* <span class="material-symbols-outlined">sentiment_satisfied </span> */}
              <span class="countingClass">
                <CountUp end={30} duration={15} />
                <span>+</span>
              </span>
              <span>Countries Clients Worldwide</span>
            </li>
            <li>
              {/* <span class="material-symbols-outlined">rate_review</span> */}
              <span class="countingClass">
                <CountUp end={50} duration={15} />
                <span>+</span>
              </span>
              <span>Year of Experience</span>
            </li>
          </ul>
        </div>
      </div>

      {/* ///////////////////////// Section 4 ////////////////////// */}

      <section className="About-Section4">
        <div className="container">
          <div className="AboutUS-Section4-con">
            <div className="row">
              <Fade left>
                <div className="col-lg-6 order-md-1 order-2">
                  {/* <div className="Mission-Logo-con">
                  <div className="Mission-Logo">
                    <img src={Mission} alt="#" />
                  </div>
                </div> */}
                  <div className="Mission-MainCon">
                    <div className="Misiion-Con">
                      <div className="MIssion-In-Con">
                        <div className="Mission-plate">
                          <h3>Mission</h3>
                        </div>
                        <p>
                          Our core values revolve around providing quality
                          chemicals, building lasting relationships, and
                          delivering outstanding customer service. We are
                          committed to meeting the needs of our customers by
                          providing safe and effective products, building strong
                          partnerships, and ensuring prompt, reliable service.
                          By upholding these values, we strive to make a
                          positive impact on our industry and the world. Trust
                          us to be your reliable partner in chemistry.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>

              <Fade right>
                <div className="col-lg-6 order-md-2 order-1">
                  <div className="Mission-Logo-con">
                    <div className="Mission-Logo">
                      <img src={Mission} alt="Leading Industrial Chemical Supplier in Mumbai" />
                    </div>
                  </div>

                  {/* <div className='Mission-MainCon'>
                  <div className='Misiion-Con'>
                    <div className="MIssion-In-Con">
                      <div className="Mission-plate">
                        <h3>Vision</h3>
                      </div>
                      <p>
                      To advance the business of our suppliers and customers while progressing quality of life for all through their products.</p>
                    </div>
                  </div>
                </div> */}
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>

      {/*  */}

      <section className="About-Section41">
        <div className="container">
          <div className="AboutUS-Section4-con">
            <div className="row">
              <Fade right>
                <div className="col-lg-6">
                  <div className="Mission-Logo-con">
                    <div className="Mission-Logo">
                      <img src={Vision} alt="Leading Industrial Chemical Supplier in Mumbai" />
                    </div>
                  </div>
                  {/* <div className='Mission-MainCon'>
                  <div className='Misiion-Con'>
                    <div className="MIssion-In-Con">
                      <div className="Mission-plate">
                        <h3>Mission</h3>
                      </div>
                      <p>
                      We focus on providing quality chemicals, building lasting relationships and delivering on our outstanding customer service.</p>
                    </div>
                  </div>
                </div> */}
                </div>
              </Fade>

              <Fade left>
                <div className="col-lg-6">
                  {/* <div className="Mission-Logo-con">
                  <div className="Mission-Logo">
                    <img src={Vision} alt="#" />
                  </div>
                </div> */}
                  <div className="Mission-MainCon">
                    <div className="Misiion-Con">
                      <div className="MIssion-In-Con">
                        <div className="Mission-plate">
                          <h3>Vision</h3>
                        </div>
                        <p>
                          Our goal is to advance the business of our suppliers
                          and customers while improving the quality of life for
                          all through the use of their products. We believe that
                          by working together, we can create a better world for
                          everyone. We're committed to providing safe and
                          effective chemicals, building strong partnerships, and
                          delivering exceptional customer service to help our
                          customers achieve their goals. Let us be your trusted
                          partner in chemistry for a brighter future.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default AboutUs;
